import CompanyImage from './assets/images/turinge_larsson_logotype.png';
import './App.css';

function App() {
    return (<div className="App">
            <div className="top-section">
                <div className="content hero">
                    <div className="company-image">
                        <img alt="Larsson i Turinge Logotype" src={CompanyImage} />
                    </div>
                    <h1 style={{ display: 'none' }}>Larsson i Turinge</h1>
                    <h2>
                        Däck, trädgårdsprodukter och mycket mer.<br /><br />
                    </h2>
                </div>
                <div className="grid-section-3 basic-info-section">
                    <div className="basic-info-column">
                        <div className="basic-info-wrapper">
                            <div className="basic-info-title">
                                <h3 style={{ marginBottom: '-17px' }}>
                                    Öppettider:
                                </h3>
                            </div>
                            <div className="basic-info-content">
                                <p>
                                    <table className="open-hours winter">
                                        <tbody>
                                        <tr>
                                            <td>Mån-Tor:</td>
                                            <td>08.00 - 17.00</td>
                                        </tr>
                                        <tr>
                                            <td>Fre:</td>
                                            <td>08.00 - 15.00</td>
                                        </tr>
                                        {/*<tr>
                                            <td>Lördag:</td>
                                            <td>08.00 - 13.00</td>
                                        </tr>
                                        <tr>
                                            <td>Söndag:</td>
                                            <td>Stängt</td>
                                        </tr>*/}
                                        </tbody>
                                    </table>
                                </p>

                                <div style={{ fontSize: '16px' }}><i><b>OBS!</b> Stängt helger och röda dagar under vintern. <br />Kontakta oss för mer information.</i>
                                    {/*Öppettiderna kan variera<br/> på röda dagar och högtider.*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="basic-info-column">
                        <div className="basic-info-wrapper">
                            <div className="basic-info-title">
                                <h3 style={{ marginBottom: '-17px' }}>
                                    Kontakta oss:
                                </h3>
                            </div>
                            <div className="basic-info-content">
                                <p>
                                    <table className="contact-info">
                                        <tbody>
                                        <tr>
                                            <td>Telefon:</td>
                                            <td>08552 403 40</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <a href="mailto:mattiaslarsson68@hotmail.com">Skicka e-post</a>
                                </p>
                                <p>
                                Gamla Strängnäsvägen 342<br />
                                    15591 Nykvarn<br /><br />
                                    <a
                                        href="https://www.facebook.com/profile.php?id=100068434028249"
                                        rel="noreferrer"
                                        target="_blank"
                                        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                                    >
                                        Följ oss på Facebook
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="separator" />

            <div className="grid-section-3 sales-section">
                <div className="sales-tires">
                    <div className="sales-title">
                        Däck
                    </div>
                    <div className="sales-content">
                        <div>
                            <ul>
                                <li>Däckbyte</li>
                                <li>Däckhotell</li>
                                <li>Försäljning</li>
                                <li>Omläggning</li>
                                <li>Balansering</li>
                                <li>Lagning</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sales-gardening">
                    <div className="sales-title">
                        Trädgårdsprodukter
                    </div>
                    <div className="sales-content">
                        <div>
                            <ul>
                                Jord från <a href="https://www.sodraarhultstorv.se/" rel="noreferrer" target="_blank">Södra
                                Århults
                                torv</a>.
                                <br />
                                <b style={{fontSize: '20px'}}>Kan även levereras.</b>
                                <br />
                                <br />
                                <li>
                                    <div className="list-row">Planteringsjord <div className="list-amount-content"> - 80L</div></div>
                                </li>
                                <li>
                                    <div className="list-row">Barkmull <div className="list-amount-content"> - 50L</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list-row">Täckbark <div className="list-amount-content"> - 50L</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list-row">Rosjord <div className="list-amount-content"> - 50L</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list-row">Rhododendronjord <div className="list-amount-content"> - 50L</div></div>
                                </li>
                                <li>
                                    <div className="list-row">Kogödsel <div className="list-amount-content"> - 50L</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list-row">Hönsgödsel <div className="list-amount-content"> - 15KG</div></div>
                                </li>
                                <li>
                                    <div className="list-row">Gräsgödsel <div className="list-amount-content"> - 10KG</div></div>
                                </li>
                                <li>
                                    <div className="list-row">Dressjord <div className="list-amount-content"> - 50L</div></div>
                                </li>
                                <li>
                                    <div className="list-row">Pelargonjord <div className="list-amount-content"> - 20L</div></div>
                                </li>
                                <li>
                                    <div className="list-row">Blomjord <div className="list-amount-content"> - 20L</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list-row">Urnjord <div className="list-amount-content"> - 50L</div>
                                    </div>
                                </li>
                                <li>
                                    <div className="list-row">Naturtorv <div className="list-amount-content"> - 80L</div></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sales-misc">
                    <div className="sales-title">
                        Övrigt
                    </div>
                    <div className="sales-content">
                        <div>
                            <ul>
                                <li>
                                    <div className="list-row">Vedförsäljning <div className="list-amount-content"> -
                                        även leverans</div></div>
                                </li>
                                <li>Biltillbehör</li>
                                <li>Mindre kiosk</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="separator" />

            <footer>
            <p>Larsson I Turinge AB<br />
                    <a
                        href="https://www.facebook.com/profile.php?id=100068434028249"
                        rel="noreferrer"
                        target="_blank"
                        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                    >
                        <b>Följ oss på Facebook</b>
                    </a>
                </p>
                <p>
                    08552 403 40
                </p>
                <p>
                    Gamla Strängnäsvägen 342<br />
                    15591 Nykvarn<br /><br />
                </p>
            </footer>
        </div>
    );
}

export default App;
